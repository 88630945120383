<template>
  <div class="container">

    <DuButton text="اضافة" class="addNewButton" @click="openAddDialog()"/>


    <form class="filterBar" @submit="filterResults" v-if="!state.isLoadingCats">
      <input placeholder="ابحث عن مكان" class="mainInput" name="placeNamesQuery"/>
      <select name="area">
        <option value="">جميع المدن</option>
        <option :value="cat.value" v-for="cat in getCatsListToRender('area')">{{ cat.text }}</option>
      </select>
      <select name="cat">
        <option value="">جميع التصنيفات</option>
        <option :value="cat.value" v-for="cat in getCatsListToRender('cat')">{{ cat.text }}</option>
      </select>
      <button class="searchButton">
        <IconsWorker :width="20" color="#fff" icon="searchF"/>
      </button>

    </form>

    <div class="list">
      <DuList
          type="applysControlPanel"
          :scroll-body="getScrollBody()"
          :key="state.lightBoxId"
          :exstra-parameters="{...state.exstraParams}"
          v-if="!state.isLoadingCats" >
        <template v-slot:noResults>
          <div class="noResults">
            لا يوجد نتائج
          </div>
        </template>
      </DuList>
    </div>
  </div>
  <AddOrEditApplyLightBox v-if="state.showAddDialog" :onDone="() => onAddOrEditDone()" :onCancel="() => onCancel()"/>
</template>

<script>
import DuList from "../../components/DuList";
import DuButton from "../../components/DuButton";
import AddOrEditApplyLightBox from "../AddOrEditApplyLightBox";
import {onMounted, reactive, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import IconsWorker from "../../components/IconsWorker";
import AjaxWorker from "../../helpers/AjaxWorker";
export default {
  name: "Applys",
  components: {IconsWorker, AddOrEditApplyLightBox, DuButton, DuList},
  setup() {

    const state = reactive({
      showAddDialog: false,
      lightBoxId: `dialogAdd-${Math.random().toLocaleString(16)}`,
      exstraParams: {},
      isLoadingCats: true,
      listOfCats: [],
    });



    const getScrollBody = () => document.getElementsByTagName("body")[0];

    const onAddOrEditDone = () => {
      state.showAddDialog = false;
      state.lightBoxId = `dialogAdd-${Math.random().toLocaleString(16)}`;
    }
    const onCancel = () => {
      state.showAddDialog = false;
      state.lightBoxId = `dialogAdd-${Math.random().toLocaleString(16)}`;
    }

    const openAddDialog = () => {
      state.showAddDialog = true;
    }

    const route = useRoute();
    watch(() => route.path, () => {
      state.lightBoxId = `dialogAdd-${Math.random().toLocaleString(16)}`;
    });

    const filterResults = (e) => {
      const _exstraParams = {};
      const formData = new FormData(e.target);
      const area = formData.get('area');
      const cat = formData.get('cat');
      const placeNamesQuery = formData.get('placeNamesQuery');

      if(area !== '') {
        _exstraParams.area = area;
      }
      if(cat !== '') {
        _exstraParams.cat = cat;
      }
      if(placeNamesQuery !== '') {
        _exstraParams.placeNamesQuery = placeNamesQuery;
      }

      state.exstraParams = _exstraParams;
      e.preventDefault();
      return false;
    }

    const getCats = () => {
      new AjaxWorker().request('/transactions.php?action=getCats&getAll=true').then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getCats();
          },2000);
        } else {
          state.isLoadingCats = false;
          state.listOfCats = res.data;
        }
      });
    }

    onMounted(() => {
      getCats();
    });

    const getCatsListToRender = (type) => {
      const _list = [];
      state.listOfCats.forEach((item) => {
        if(item.type === type) {
          _list.push({
            text: item.title,
            value: item.id,
          });
        }
      });
      return _list;
    }


    return {
      getScrollBody,
      getCatsListToRender,
      openAddDialog,
      filterResults,
      onAddOrEditDone,
      onCancel,
      state,
    };
  }
}
</script>

<style scoped>
  .container{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .addNewButton{
    place-self: end;
    margin: 10px;
  }

  .list{
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;
    margin: 10px 0;
  }

  .filterBar{
    width: available;
    display: flex;
    flex-direction: row;
    background: #eee;
    border-radius: 100px;
    padding: 10px;
    margin: 20px 0;
    gap: 10px;
    align-items: center;
    justify-content: start;
    justify-items: start;
    align-content: start;
  }

  .filterBar input,select{
    padding: 10px 15px;
    border-radius: 100px;
    border: 0;
    font-size: 16px;
    width: max-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .filterBar select{
    cursor: pointer;
  }



  .filterBar .searchButton{
    padding: 10px;
    border-radius: 100px;
    background: #FF0029;
    cursor: pointer;
    border: 0;
  }

  .mainInput{
    flex: 1;
  }

  .filterBar .searchButton:hover{ opacity: 0.7; }
  .filterBar .searchButton:active{ opacity: 0.4; }

  .noResults{
    padding: 50px 0;
  }

  @media only screen and (max-width: 600px) {
    .filterBar {
      flex-direction: column;
      border-radius: 10px;
      gap: 5px;
    }
    .filterBar input,select{
      width: 80%;
      padding: 10px 10%;
      margin: 5px 0;
      min-width: available;
      flex: 1;
    }
    .filterBar select{
      width: 100%;
    }
    .filterBar .searchButton{
      place-self: end;
    }
  }
</style>